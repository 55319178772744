import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";

const ChartRiwayat = (props) => {
  let namaKec = [];
  let percent = [];

  const [x, setX] = useState(['x', 'y']);
  const [y, setY] = useState([1, 2]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    namaKec = props?.data?.map(({ kecamatan }) => kecamatan);
    setX(namaKec);

    const sum = props?.data?.reduce(
      (acc, { total_tagihan }) => acc + total_tagihan * 1,
      0
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    percent = props?.data?.map(
      ({ total_tagihan }) => (total_tagihan / sum) * 100
    );

    if(percent) {setY(Object.values(percent))}
    
  }, [props]);

  return (
    <div className="mt-5">
      <ReactApexChart
        options={{
          labels: x,
        }}
        series={y}
        type="donut"
        height={252}
      />
    </div>
  );
};

export default ChartRiwayat;
