import Layout from "./page2/Layout";

const Main = () => {
  const pdam = localStorage.getItem("pdam");

  return( 
  <div className="h-full md:h-screen flex">
    {
      pdam ? <Layout /> : window.open('/', '_self')
    }
  </div>);
};

export default Main;
