import Dashboard from "./Dashboard";
import Pembayaran from "./Pembayaran";
// import logo from "../../assets/logo.png";
import { useState } from "react";
import { Icon } from "@iconify/react";
import Allusers from "./Allusers";
import AddUser from "./AddUser";

const Layout = () => {
  const pdam = localStorage.getItem("pdam");
  const [sideActive, setSideActive] = useState({ d: 1, p: 0, a: 0, u: 0 });
  const [toggle, setToggle] = useState(false);

  const active = {
    bg: "bg-[#158087] w-full rounded-l-[15px] text-white",
    color: "#474747",
  };

  function klikDashboard() {
    setSideActive({
      d: 1,
      p: 0,
      a: 0,
      u: 0,
    });
  }

  function klikHistory() {
    setSideActive({
      d: 0,
      p: 1,
      a: 0,
      u: 0,
    });
  }

  function klikAllusers() {
    setSideActive({
      d: 0,
      p: 0,
      a: 1,
      u: 0,
    });
  }

  function klikAdduser() {
    setSideActive({
      d: 0,
      p: 0,
      a: 0,
      u: 1,
    });
  }

  // console.log(sideActive);

  function toggle_f() {
    setToggle(toggle ? false : true);
  }

  const logout = () => {
    localStorage.removeItem("pdam");
    localStorage.removeItem("token");
    window.open("/", "_self");
  };

  // console.log(toggle);

  return (
    <div className={`w-full flex relative pb-5 font-poppins`}>
      {/* sidebar */}
      <div
        id="sidebar"
        className={`w-[200px] h-screen bg-white text-[#474747] flex-shrink-0 absolute top-0 left-0 z-50  ${
          toggle ? "inline" : "invisible md:visible md:relative"
        }`}
      >
        <div className="ml-[45px] pt-10">
          <img src={`${process.env.REACT_APP_ASSETS}/login/logo_siab_asli.png`} alt="logo" className="w-[83px] h-[43px]" />
        </div>
        <div className="flex flex-col items-center">
          <div>
            <hr className="bg-slate-400 w-[150px] h-[2px] mt-[24px] border-0" />
          </div>
          <div id="menu" className="mt-[74px] w-full pl-[10px]">
            <div
              className={`py-2 flex items-center cursor-pointer ${
                sideActive.d ? active.bg : ""
              }`}
              onClick={klikDashboard}
            >
              <Icon
                icon="material-symbols:dashboard-outline-rounded"
                className="w-[24px] h-[24px] ml-[18px]"
                color={sideActive.d ? "white" : active.color}
              />
              <p className="font-semibold text-[13px] ml-[7px]">Dashboard</p>
            </div>
            <div
              className={`py-2 flex items-center my-3 cursor-pointer ${
                sideActive.p ? active.bg : ""
              }`}
              onClick={klikHistory}
            >
              <Icon
                icon="tabler:report-money"
                color={sideActive.p ? "white" : active.color}
                className="w-[24px] h-[24px]  ml-[18px]"
              />
              <p className="font-semibold text-[13px] ml-[7px]">Pembayaran</p>
            </div>
            <div
              className={`py-2 flex items-center my-3 cursor-pointer ${
                sideActive.a ? active.bg : ""
              }`}
              onClick={klikAllusers}
            >
              <Icon
                icon="ci:users"
                color={sideActive.a ? "white" : active.color}
                className="w-[24px] h-[24px]  ml-[18px]"
              />
              <p className="font-semibold text-[13px] ml-[7px]">Data User</p>
            </div>
            <div
              className={`py-2 flex items-center my-3 cursor-pointer ${
                sideActive.u ? active.bg : ""
              }`}
              onClick={klikAdduser}
            >
              <Icon
                icon="icon-park-outline:doc-add"
                color={sideActive.u ? "white" : active.color}
                className="w-[24px] h-[24px]  ml-[18px]"
              />
              <p className="font-semibold text-[13px] ml-[7px]">Tambah User</p>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-4 left-1/2 -translate-x-1/2 cursor-pointer group p-2 w-10 h-10"
          title="log out"
          onClick={logout}
        >
          <Icon
            icon="material-symbols:door-front-rounded"
            width="26"
            className="group-hover:hidden"
          />
          <Icon
            icon="material-symbols:door-open"
            width="26"
            className="group-hover:inline hidden"
          />
        </div>
      </div>

      {/* header */}
      <div
        id="header"
        className="w-full mt-[18px] mx-3 md:mx-10 overflow-y-auto overflow-x-hidden"
      >
        <div className="flex justify-between items-center">
          <h1 className="text-[#474747] font-bold uppercase">admin {pdam}</h1>
          <div className="md:invisible visible -mr-1">
            <Icon
              icon="ic:round-close"
              width="32"
              height="32"
              className={`cursor-pointer ${toggle ? "" : "hidden"}`}
              onClick={toggle_f}
            />
            <Icon
              icon="gg:menu-right-alt"
              width="32"
              height="32"
              className={`cursor-pointer ${toggle ? "hidden" : ""}`}
              onClick={toggle_f}
            />
          </div>
        </div>
        {/* content */}
        <div className={`${sideActive?.d ? "inline" : "hidden"}`}>
          <Dashboard />
        </div>
        <div className={`${sideActive?.p ? "inline" : "hidden"}`}>
          <Pembayaran />
        </div>
        <div className={`${sideActive?.a ? "inline" : "hidden"}`}>
          <Allusers />
        </div>
        <div className={`${sideActive?.u ? "inline" : "hidden"}`}>
          <AddUser />
        </div>
      </div>
    </div>
  );
};

export default Layout;
