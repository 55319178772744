// import logo from '../assets/logo.png'

const Logo = () => {
  return (
    <div className="pl-[45px] pt-[25px]">
        <img src={`${process.env.REACT_APP_ASSETS}/login/logo_siab_asli.png`} alt="logo" className='w[83px] h-[43px]'/>
    </div>
  )
}

export default Logo