import React, { useRef, useEffect } from "react";
import QRCode from "qrcode";

const QrCode = ({ qrString }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Generate the QR code
        await QRCode.toCanvas(canvas, qrString, { errorCorrectionLevel: "H" });
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    };

    generateQRCode();
  }, [qrString]);

  return <canvas width="200" height="200" ref={canvasRef} />;
};

export default QrCode;
