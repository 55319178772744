import ReactApexChart from "react-apexcharts";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import PuffLoader from "react-spinners/PuffLoader";
import { downloadCsv } from "../Functions";

const Chart = () => {
  let x = new Date();
  let thisYear = x.getFullYear();

  const [dataFetch, setDataFetch] = useState();
  const [val, setVal] = useState(thisYear);
  const [loading, setLoading] = useState(false);
  const pdam = localStorage.getItem("pdam");
  const select = (val) => {
    setVal(val.target.value);
  };

  useEffect(() => {
    if (pdam) {
      setLoading(true);
      async function waha() {
        const data = (
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/lora/v1/data/admin-chart`,
            {
              pdam: pdam,
              tahun: val,
            }
          )
        ).data;
        data["tahun"] = val
        setDataFetch(data);
        setLoading(false);
      }
      waha();
    } else {
      window.open("/", "_self");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdam, val]);

  const namaBulan = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const arrBulan = dataFetch?.totalRevenuePerYear?.map((b) => {
    return namaBulan[b.bulan - 1];
  });

  const arrData = dataFetch?.totalRevenuePerYear?.map((b) => {
    return (b.total_tagihan / 1000000).toFixed(3);
  });

  const arrKonsumsi = dataFetch?.penggunaanAirPerBulan?.map((b) => {
    return (b.total_penggunaan / 1000).toFixed(3);
  });

  const data = {
    series: [
      {
        name: "Revenue (jt)",
        type: "column",
        data: arrData,
      },
      {
        name: "Penggunaan air (m3)",
        type: "line",
        data: arrKonsumsi,
      },
    ],
    options: {
      colors: ["#279FFA", "#19E5A0"],
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      title: {
        text: "Konsumsi dan Pendapatan tahun",
        align: "left",
      },
      stroke: {
        width: [0, 4],
      },
      xaxis: {
        type: "string",
        categories: arrBulan,
      },
      yaxis: [
        {
          title: {
            text: "Revenue (jt)",
          },
        },
        {
          opposite: true,
          title: {
            text: "Penggunaan air (m3)",
          },
        },
      ],
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  return (
    <div className="bg-white relative">
      {dataFetch !== undefined && (
        <>
          <select
            className="border border-slate-300 text-slate-500 px-3 rounded-md absolute left-[270px] hidden md:block text-sm z-50 focus:outline-none"
            onChange={select}
            value={val}
          >
            {dataFetch?.semuaTahun.map((tahun, i) => (
              <option key={i} value={tahun}>
                {tahun}
              </option>
            ))}
          </select>
          <div className="absolute left-[360px] z-[99]">
            <PuffLoader color="#38BDF8" size={25} loading={loading} />
          </div>
          <div
            className="absolute right-5 cursor-pointer z-[99]"
            title="Download excel"
            onClick={() => downloadCsv(dataFetch, "revenue")}
          >
            <Icon
              icon="ic:round-download"
              width={25}
              className="text-gray-500 hover:text-gray-700"
            />
          </div>
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="area"
            height={320}
          />
        </>
      )}
    </div>
  );
};

export default Chart;
