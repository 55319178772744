import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import ChartRevenue from "./ChartRevenue";
import ChartTPK from "./ChartTPK";
import Peta from "./Peta";
import axios from "axios";

const Dashboard = () => {
  // let docTile = document.title;
  // window.addEventListener("blur", () => {
  //   document.title = "comeback :(";
  // });
  // window.addEventListener("focus", () => {
  //   document.title = docTile;
  // });


  const idr = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);
  };

  const [data, setData] = useState();

  const pdam = localStorage.getItem("pdam");

  const getPos = (e) => {
    document.getElementById("map").scrollIntoView({ behavior: "smooth" });
  };

  async function login() {
    try {
      const x = (
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/lora/v1/data/admin`,
          {
            pdam: pdam,
          }
        )
      ).data;
      setData(x);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (pdam) {
      login();
    } else {
      window.open("/", "_self");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data === undefined ? (
    <p className="text-[12px] font-medium">Loading Data...</p>
  ) : (
    <div className="relative flex">
      <div className="h-full w-full">
        <div id="content" className="mt-[28px] drop-shadow-lg flex flex-col">
          {/* cards */}
          <div
            id="atas"
            className="flex flex-col gap-10 md:gap-[15px] md:flex-row"
          >
            {/* each card */}
            <div className="bg-white w-full md:w-full rounded-lg px-[20px] py-1 flex justify-between items-center relative">
              <div className="bg-[#658CD9] absolute w-[34px] h-[34px] drop-shadow-lg rounded-full -top-7 left-1/2 -translate-x-1/2 flex justify-center items-center">
                <Icon
                  icon="uil:tachometer-fast-alt"
                  width="27"
                  color="white"
                  className="-mt-1"
                />
              </div>
              <div className="text-[#808184] flex flex-col items-center">
                <p className="font-medium text-[12px]">Total</p>
                <p className="font-semibold text-[24px] -mt-1">
                  {data?.totalDevices}
                </p>
                <p className="-mt-1 font-medium text-[8px]">keseluruhan</p>
              </div>
              <p className="text-black font-medium text-[12px] text-center ml-7">
                Info <br></br> Devices
              </p>
              <div
                className="text-[#D7B81A] flex flex-col items-center cursor-pointer"
                title="lihat detail"
                onClick={getPos}
              >
                <p className="font-medium text-[12px]">Maintenance</p>
                <p className="font-semibold text-[24px] -mt-1 relative">
                  {data?.maintenanceDevicesCount > 0 && (
                    <span>
                      <Icon
                        icon="bi:exclamation"
                        width="30"
                        color="black"
                        className={`absolute top-1 -left-7 rotate-[-23deg] animate-goyang`}
                      />
                    </span>
                  )}
                  {data?.maintenanceDevicesCount}
                </p>
                <p className="font-medium text-[8px] -mt-1">keseluruhan</p>
              </div>
            </div>
            {/* each card */}
            <div className="bg-white w-full md:w-full rounded-lg px-[20px] py-1 flex justify-between items-center relative">
              <div className="bg-[#A054BB] absolute w-[34px] h-[34px] drop-shadow-lg rounded-full -top-7 left-1/2 -translate-x-1/2 flex justify-center items-center">
                <Icon
                  icon="quill:send-later"
                  width="24"
                  color="white"
                  className="mt-[2px] -ml-[1px]"
                />
              </div>
              <div className="text-[#6599AE] flex flex-col items-center">
                <p className="font-medium text-[12px]">Success</p>
                <p className="font-semibold text-[24px] -mt-1">
                  {data?.arrSendedToday}
                </p>
                <p className="-mt-1 font-medium text-[8px]">per hari ini</p>
              </div>
              <p className="text-black font-medium text-[12px] text-center ml-0">
                Status <br /> Pengiriman <br /> Data
              </p>
              <div className="text-[#808184] flex flex-col items-center">
                <p className="font-medium text-[12px]">Pending</p>
                <p className="font-semibold text-[24px] -mt-1 relative">
                  {data?.arrNotSendedToday}
                </p>
                <p className="font-medium text-[8px] -mt-1">per hari ini</p>
              </div>
            </div>
            {/* each card */}
            <div className="bg-white w-full md:w-[284px] rounded-lg px-[20px] py-1 flex justify-between items-center flex-shrink-0 relative">
              <div className="bg-[#D1B000] absolute w-[34px] h-[34px] drop-shadow-lg rounded-full -top-7 left-1/2 -translate-x-1/2 flex justify-center items-center">
                <Icon
                  icon="bi:cash-coin"
                  width="21"
                  color="white"
                  className="mt-1 -ml-[1px]"
                />
              </div>
              <div className="text-[#62BB47] flex flex-col items-center">
                <p className="font-medium text-[12px]">Lunas</p>
                <p className="font-semibold text-[24px] -mt-1">
                  {data?.arrPaid}
                </p>
                <p className="-mt-1 font-medium text-[8px]">per bulan lalu</p>
              </div>
              <p className="text-black font-medium text-[12px] text-center ml-0">
                Status <br /> Pembayaran
              </p>
              <div className="text-[#D8616F] flex flex-col items-center">
                <p className="font-medium text-[12px]">Belum</p>
                <p className="font-semibold text-[24px] -mt-1 relative">
                  {data?.arrUnPaid}
                </p>
                <p className="font-medium text-[8px] -mt-1">per bulan lalu</p>
              </div>
            </div>
          </div>
          {/* chart revenue */}
          <div className="flex flex-col md:flex-row gap-[15px]">
            <div className="bg-white w-full h-[361px] md:w-full mt-[15px] rounded-lg p-[12px]">
              <ChartRevenue />
            </div>
            <div className="bg-white w-full h-auto md:w-[284px] md:h-[361px] mt-[15px] rounded-lg p-[12px] font-medium flex-shrink-0">
              <p className="text-[14px]">Total Tagihan Bulan Lalu</p>
              <p className="text-[25px] my-[25px] text-center font-bold">
                {idr(data?.totalRevenuePerMonth)}
              </p>
              <div className="px-5 text-[10px] mt-5 text-[#636363]">
                <p className="font-medium text-[12px] mb-1">Kecamatan</p>
                {data?.totalRevenuePerKecamatan?.map((x, i) => (
                  <div key={i} className="flex justify-between">
                    <p>{x.kecamatan}</p>
                    <p>{idr(x?.total_tagihan)}</p>
                  </div>
                ))}
              </div>
              <ChartTPK data={data?.totalRevenuePerKecamatan} />
            </div>
          </div>
          {/* peta */}
          <div id="map">
            <Peta pos={data?.listDevicesData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
