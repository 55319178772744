import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import React from "react";
import L from "leaflet";

const Peta = (props) => {
  // console.log(props.pos)

  //epoch to human time
  function humanTime(epoch) {
    return new Date(epoch * 1).toLocaleString("id-ID");
  }

  const redIcon = new L.Icon({
    iconUrl:
      "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const blueIcon = new L.Icon({
    iconUrl:
      "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <div className="w-full h-[500px] mt-[15px] drop-shadow-xl bg-white p-3 rounded-xl mb-5 relative overflow-hidden">
      <MapContainer
        className="w-full h-full z-0"
        center={[-2.00638, 116.819769]}
        zoom={5}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.pos.map((posisi, i) => (
          <Marker
            key={i}
            position={[posisi.lat * 1, posisi.lng * 1]}
            icon={posisi.status === "need-maintain" ? redIcon : blueIcon}
          >
            <Tooltip>
              <p>Nama: {posisi.nama}</p>
              <p>deveui: {posisi.devEui}</p>
              <p>Last Send: {humanTime(posisi.lastSend)}</p>
              <p>error: {posisi.error}</p>
              <p>delay: {posisi.delay}</p>
              <p>Posisi: {[posisi.lat * 1, " || ", posisi.lng * 1]}</p>
              <p>PDAM: {posisi.pdam}</p>
              <p>Kec: {posisi.kecamatan}</p>
              <p>Kel | Gol: {[posisi.kelompok, " | ", posisi.golongan]}</p>
              <p>Telp: {posisi.telp}</p>
            </Tooltip>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default Peta;
