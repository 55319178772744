import React, { useEffect, useState } from "react";
import axios from "axios";

const AddUser = () => {
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arrKelompok, setArrKelompok] = useState([]);
  const [toaster, setToaster] = useState({
    pop: false,
    msg: "",
  });

  const [formData, setFormData] = useState({
    pdam: localStorage.getItem("pdam"),
    nama: "",
    deveui: "",
    kelompok: "",
    golongan: "",
    telp: "",
    kec: "",
    lat: "",
    lng: "",
    alamat: "",
  });

  const handleFormData = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelect = (e) => {
    setFormData((prev) => ({
      ...prev,
      kelompok: arrKelompok[e.target.value].kelompok,
      golongan: arrKelompok[e.target.value].golongan,
    }));
  };

  // logout
  const logout = () => {
    localStorage.removeItem("pdam");
    localStorage.removeItem("token");
    window.open("/", "_self");
  };

  const handleSimpan = async () => {
    if (validate === false) return;
    setLoading(true);
    try {
      const simpan = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/new-device-admin`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading(false);
      console.log(simpan.data);
      if (simpan.data.status === "success") {
        const updatedX = Object.fromEntries(
          Object.keys(formData).map((key) => {
            if (key === "pdam" || key === "kelompok" || key === "golongan") {
              return [key, formData[key]];
            }
            return [key, ""];
          })
        );       

        setFormData(updatedX);

        setToaster({
          ...toaster,
          pop: true,
          msg: simpan.data.msg,
        });
        setTimeout(() => {
          setToaster({
            ...toaster,
            pop: false,
          });
        }, 3000);
      } else if (simpan.data.status === "exist") {
        setToaster({
          ...toaster,
          pop: true,
          msg: simpan.data.msg,
        });
        setTimeout(() => {
          setToaster({
            ...toaster,
            pop: false,
          });
        }, 3000);
      } else if (simpan.data.status === "rejected") {
        setToaster({
          ...toaster,
          pop: true,
          msg: simpan.data.msg,
        });
        setTimeout(() => {
          setToaster({
            ...toaster,
            pop: false,
          });
          logout()
        }, 3000);
      } else {
        setToaster({
          ...toaster,
          pop: true,
          msg: simpan.data.msg,
        });
        setTimeout(() => {
          setToaster({
            ...toaster,
            pop: false,
          });
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    async function fetchingPdam() {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-new-device`,
          {
            kota: localStorage.getItem("pdam"),
            askList: false,
          }
        )
        .then(function (response) {
          setArrKelompok(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchingPdam();
  }, []);

  useEffect(() => {
    setValidate(Object.values(formData).every((value) => value !== ""));
  }, [formData]);

  return (
    <div className="mt-[50px]">
      <div className="bg-white mt-5 flex flex-col p-5 items-center py-10">
        <p className="font-semibold">Tambah User Baru</p>
        <div className="w-[50%] mt-5 flex flex-col gap-5 text-[14px] text-gray-500">
          <label>
            <p className="font-semibold">Kelompok</p>
            <select
              className="border border-[#38BDF8] w-full py-2 px-2"
              onChange={handleSelect}
              defaultValue="Pilih kelompok"
            >
              <option disabled>Pilih kelompok</option>
              {arrKelompok.map((kel, i) => (
                <option key={i} value={i}>
                  {"kelompok " + kel.kelompok + ", golongan" + kel.golongan}
                </option>
              ))}
            </select>
          </label>
          <label>
            <p className="font-semibold">Nama</p>
            <input
              type="text"
              placeholder="Masukkan nama user"
              className="border border-[#38BDF8] w-full py-2 px-2"
              name="nama"
              value={formData.nama}
              onChange={handleFormData}
            />
          </label>
          <label>
            <p className="font-semibold">Deveui</p>
            <input
              type="text"
              placeholder="Masukkan deveui device"
              className="border border-[#38BDF8] w-full py-2 px-2"
              name="deveui"
              value={formData.deveui}
              onChange={handleFormData}
            />
          </label>
          <label>
            <p className="font-semibold">Telp</p>
            <input
              type="text"
              placeholder="Masukkan nomor telepon"
              className="border border-[#38BDF8] w-full py-2 px-2"
              name="telp"
              value={formData.telp}
              onChange={handleFormData}
            />
          </label>
          <label>
            <p className="font-semibold">Kecamatan</p>
            <input
              type="text"
              placeholder="Nama kecamatan"
              className="border border-[#38BDF8] w-full py-2 px-2"
              name="kec"
              value={formData.kec}
              onChange={handleFormData}
            />
          </label>
          <label>
            <p className="font-semibold">Latitude (garis lintang)</p>
            <input
              type="text"
              placeholder="Posisi garis lintang"
              className="border border-[#38BDF8] w-full py-2 px-2"
              name="lat"
              value={formData.lat}
              onChange={handleFormData}
            />
          </label>
          <label>
            <p className="font-semibold">Longitude (garis bujur)</p>
            <input
              type="text"
              placeholder="Posisi garis bujur"
              className="border border-[#38BDF8] w-full py-2 px-2"
              name="lng"
              value={formData.lng}
              onChange={handleFormData}
            />
          </label>
          <label>
            <p className="font-semibold">Alamat</p>
            <input
              type="text"
              placeholder="Alamat rumah user"
              className="border border-[#38BDF8] w-full py-2 px-2"
              name="alamat"
              value={formData.alamat}
              onChange={handleFormData}
            />
          </label>
          <button
            className={`${
              validate
                ? "bg-[#38BDF8] text-white hover:bg-[#26b0eb]"
                : "bg-gray-300 cursor-default"
            } py-3 rounded-lg text-[16px] font-semibold mt-5`}
            onClick={handleSimpan}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          {toaster.pop && <p>{toaster.msg}</p>}
        </div>
      </div>
    </div>
  );
};

export default AddUser;
