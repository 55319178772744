const XLSX = require("xlsx");

export const convertStatus = (ketAlat) => {
  let arrStatus = ketAlat.split("");

  let status = "";
  let s1 = "";
  let s2 = "";

  if (parseInt(arrStatus[0]) === 0 && parseInt(arrStatus[1]) === 0) {
    status = "Normal";
  } else {
    if (parseInt(arrStatus[0]) === 1) {
      s1 = "Pipa bocor";
    } else if (parseInt(arrStatus[0]) === 2) {
      s1 = "Temperature error";
    } else if (parseInt(arrStatus[0]) === 4) {
      s1 = "Pipa kosong atau kerusakan sensor";
    } else if (parseInt(arrStatus[0]) === 8) {
      s1 = "Instalasi pipa terbalik";
    }

    if (parseInt(arrStatus[1]) === 1) {
      s2 = "Aliran balik";
    } else if (parseInt(arrStatus[1]) === 2) {
      s2 = "Aliran balik";
    } else if (parseInt(arrStatus[1]) === 4) {
      s2 = "Baterai lemah";
    } else if (parseInt(arrStatus[1]) === 8) {
      s2 = "Pipa pecah";
    }

    if (s1 !== "" && s2 !== "") {
      status = s1 + " dan " + s2;
    } else if (s1 !== "") {
      status = s1;
    } else if (s2 !== "") {
      status = s2;
    }
  }
  return status;
};

export function humanTime(epoch) {
  return new Date(epoch * 1).toLocaleString("id-ID");
}

export const downloadCsv = (datas, choosen) => {
  if (datas.length === 0) return;

  let data = [];

  if (choosen === "Semua user") {
    datas.map((obj, i) =>
      data.push({
        no: i + 1,
        nama: obj.nama,
        deveui: obj.deveui,
        kecamatan: obj.kecamatan,
        pdam: obj.pdam,
        kelompok: obj.kelompok,
        golongan: obj.golongan,
        latitut: obj.lat,
        longitut: obj.lng,
        telp: obj.telp,
      })
    );
  }

  if (choosen === "Bill bulan lalu") {
    datas.map((obj, i) =>
      data.push({
        no: i + 1,
        nama: obj.nama,
        deveui: obj.deveui,
        penggunaan_m3: obj.penggunaan / 1000,
        tagihan: obj.tagihan,
        status: obj.status,
        bulan: obj.bulan,
        tahun: obj.tahun,
        pdam: obj.pdam,
        dibayar_pada: obj.dibayar_pada > 0 ? humanTime(obj.dibayar_pada) : "-",
      })
    );
  }

  if (choosen === "Semua data terakhir") {
    datas.map((obj, i) =>
      data.push({
        no: i + 1,
        deveui: obj.deveui,
        penggunaan_m3: obj.volume / 1000,
        temperature: obj.temperature,
        data_pada: obj.created_at > 0 ? humanTime(obj.created_at) : "-",
      })
    );
  }

  if (choosen === "cari") {
    datas.map((obj, i) =>
      data.push({
        no: i + 1,
        nama: obj.nama,
        deveui: obj.deveui,
        penggunaan_m3: obj.penggunaan / 1000,
        tagihan: obj.tagihan,
        bulan: obj.bulan,
        tahun: obj.tahun,
        status: obj.status,
        dibayar_pada: obj.dibayar_pada > 0 ? humanTime(obj.dibayar_pada) : "-",
      })
    );
  }

  if (choosen === "revenue") {
    let oke = [];
    datas.penggunaanAirPerBulan.map((obj, i) =>
      oke.push({
        bulan: obj.bulan,
        total_penggunaan: obj.total_penggunaan / 1000,
        tahun: datas.tahun,
      })
    );
    console.log(datas, oke);
    oke.map((x, i) =>
      data.push({
        no: i + 1,
        bulan: x.bulan,
        tahun: x.tahun,
        total_penggunaan_m3: x.total_penggunaan,
        total_tagihan: datas.totalRevenuePerYear[i].total_tagihan,
      })
    );
  }
  console.log(datas);

  // Buat objek Workbook
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);

  // Tambahkan worksheet ke workbook
  XLSX.utils.book_append_sheet(wb, ws, choosen);

  // Simpan workbook ke dalam file Excel
  XLSX.writeFile(wb, `${choosen}.xlsx`);
};
