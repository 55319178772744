import axios from "axios";
import React, { useState } from "react";
import { Icon } from "@iconify/react";
import PuffLoader from "react-spinners/PuffLoader";

import { convertStatus, humanTime, downloadCsv } from "../Functions";

const Allusers = () => {
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(-1);
  const [edit, setEdit] = useState([]);
  const [choosen, setChoosen] = useState("");
  const [cari, setCari] = useState("");
  const opt = [
    { title: "Semua user", endPoint: "all-users" },
    { title: "Bill bulan lalu", endPoint: "all-bills" },
    { title: "Semua data terakhir", endPoint: "all-last-data" },
  ];

  // fetch data
  const getData = async (val) => {
    setLoading(true);
    try {
      const xx = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/admin/${opt[val].endPoint}`,
        {
          pdam: localStorage.getItem("pdam"),
        }
      );
      setData(xx.data);
      setLoading(false);
      setChoosen(opt[val].title);
      // set button action pada pencarian semua user
      let arr = [];
      if (val === "0") {
        xx.data.map((_, i) => arr.push({ status: false, index: i }));
        setEdit(arr);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const mencari = async () => {
    if (cari === "") {
      return;
    }
    setChoosen("cari");
    setLoading(true);
    try {
      const xx = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/admin/per-user-bill`,
        {
          deveui: cari,
        }
      );
      setData(xx.data);
      setLoading(false);
      setCari("");
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  // handle action
  const handleAction = async (i, x) => {
    // copy data dengan index tersebut (i)
    console.log(x);
    if (x === false) {
      setDataCopy(data[i]);
    } else {
      // handleSave
      setLoadingSave(i);
      let dataWithToken = dataCopy;
      dataWithToken["token"] = localStorage.getItem("token");
      const update = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/admin/update-user-data`,
        dataWithToken
      );
      console.log(update);
      setLoadingSave(-1);
      if (update.data.msg === "success") {
        console.log("sukses");
        getData(0);
      } else if (update.data.msg === "jwt expired") {
        localStorage.removeItem("pdam");
        localStorage.removeItem("token");
        window.open("/", "_self");
      }
    }

    setEdit((prev) => ({
      ...prev,
      [i]: { status: !edit[i].status, index: i },
    }));
  };

  return (
    <div className="mt-[50px]">
      <div className="bg-white mt-5 flex flex-col">
        <div className="flex justify-between items-center">
          <div className="py-3 ml-3 flex items-center gap-5">
            <select
              className="h-[30px] border border-[#38BDF8] outline-none rounded-md text-[14px] text-gray-500"
              onChange={(val) => getData(val.target.value)}
              defaultValue="Pilih Status..."
            >
              <option disabled>Pilih Status...</option>
              {opt.map((op, i) => (
                <option key={i} value={i}>
                  {op.title}
                </option>
              ))}
            </select>
            <PuffLoader color="#38BDF8" size={30} loading={loading} />
            <div className="text-[14px] flex gap-2 items-center ml-10">
              <p className="text-[#474747]">Bills spesifik user</p>
              <input
                type="text"
                placeholder="Masukkan deveui"
                value={cari}
                onChange={(e) => setCari(e.target.value)}
                className="border focus:outline-[#38BDF8] rounded-md pl-2 py-1 "
              />
              <button
                className={`${
                  cari !== "" ? "bg-[#38BDF8] text-white" : "bg-gray-300"
                } py-1 px-5 rounded-md`}
                onClick={mencari}
              >
                Cari
              </button>
            </div>
          </div>
          <div
            className="mr-3"
            title="Download EXCEL"
            onClick={() => downloadCsv(data, choosen)}
          >
            <Icon
              icon="ic:round-download"
              width={30}
              className={`text-[#6b7280] hover:text-[#374151] cursor-pointer`}
            />
          </div>
        </div>
        {choosen === opt[0].title && (
          <table className="w-full">
            <thead>
              <tr className="bg-[#DCF4FF] h-[40px] text-left border-b border-[#AAE4FE] text-[#474747]">
                <th className="pl-[10px] border-r-2">No</th>
                <th className="pl-[15px]">Nama</th>
                <th>DevEui</th>
                <th>Kecamatan</th>
                <th>PDAM</th>
                <th>Kelompok</th>
                <th>Golongan</th>
                <th>Latitut</th>
                <th>Longitut</th>
                <th>Status</th>
                <th>Telp</th>
                <th className="px-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((x, i) => (
                <tr
                  key={i}
                  className="h-[40px] border-b border-[#AAE4FE] font-medium text-[#898989]"
                >
                  <td className="pl-[15px] border-r-2 font-bold">{i + 1}</td>
                  <td className="pl-[15px]">
                    {edit[i].status ? (
                      <input
                        type="text"
                        value={dataCopy.nama}
                        onChange={(e) =>
                          setDataCopy((prev) => ({
                            ...prev,
                            nama: e.target.value,
                          }))
                        }
                        className="w-[100%] border-b-2 border-black border-dashed"
                      />
                    ) : (
                      x.nama
                    )}
                  </td>
                  <td>{x.deveui}</td>
                  <td className="pl-[15px]">
                    {edit[i].status ? (
                      <input
                        type="text"
                        value={dataCopy.kecamatan}
                        onChange={(e) =>
                          setDataCopy((prev) => ({
                            ...prev,
                            kecamatan: e.target.value,
                          }))
                        }
                        className="w-[100%] border-b-2 border-black border-dashed"
                      />
                    ) : (
                      x.kecamatan
                    )}
                  </td>
                  <td>{x.pdam}</td>
                  <td className="pl-[15px]">
                    {edit[i].status ? (
                      <input
                        type="text"
                        value={dataCopy.kelompok}
                        onChange={(e) =>
                          setDataCopy((prev) => ({
                            ...prev,
                            kelompok: e.target.value,
                          }))
                        }
                        className="w-[100%] border-b-2 border-black border-dashed"
                      />
                    ) : (
                      x.kelompok
                    )}
                  </td>
                  <td className="pl-[15px]">
                    {edit[i].status ? (
                      <input
                        type="text"
                        value={dataCopy.golongan}
                        onChange={(e) =>
                          setDataCopy((prev) => ({
                            ...prev,
                            golongan: e.target.value,
                          }))
                        }
                        className="w-[100%] border-b-2 border-black border-dashed"
                      />
                    ) : (
                      x.golongan
                    )}
                  </td>
                  <td className="pl-[15px]">
                    {edit[i].status ? (
                      <input
                        type="text"
                        value={dataCopy.lat}
                        onChange={(e) =>
                          setDataCopy((prev) => ({
                            ...prev,
                            lat: e.target.value,
                          }))
                        }
                        className="w-[100%] border-b-2 border-black border-dashed"
                      />
                    ) : (
                      x.lat
                    )}
                  </td>
                  <td className="pl-[15px]">
                    {edit[i].status ? (
                      <input
                        type="text"
                        value={dataCopy.lng}
                        onChange={(e) =>
                          setDataCopy((prev) => ({
                            ...prev,
                            lng: e.target.value,
                          }))
                        }
                        className="w-[100%] border-b-2 border-black border-dashed"
                      />
                    ) : (
                      x.lng
                    )}
                  </td>
                  <td className="pl-[15px]">
                    {edit[i].status ? (
                      <input
                        type="text"
                        value={dataCopy.status}
                        onChange={(e) =>
                          setDataCopy((prev) => ({
                            ...prev,
                            status: e.target.value,
                          }))
                        }
                        className="w-[100%] border-b-2 border-black border-dashed"
                      />
                    ) : (
                      x.status
                    )}
                  </td>
                  <td className="pl-[15px]">
                    {edit[i].status ? (
                      <input
                        type="text"
                        value={dataCopy.telp}
                        onChange={(e) =>
                          setDataCopy((prev) => ({
                            ...prev,
                            telp: e.target.value,
                          }))
                        }
                        className="w-[100%] border-b-2 border-black border-dashed"
                      />
                    ) : (
                      x.telp
                    )}
                  </td>
                  <td>
                    <div className="flex flex-col gap-2">
                      <button
                        className={`${
                          edit[i].status ? "bg-green-400" : "bg-blue-400"
                        } text-white ${loadingSave === i && "animate-pulse"}`}
                        onClick={() => handleAction(i, edit[i].status)}
                      >
                        {edit[i].status ? "Save" : "Edit"}
                      </button>
                      {edit[i].status && (
                        <button
                          className="bg-gray-700 text-white"
                          onClick={() =>
                            setEdit((prev) => ({
                              ...prev,
                              [i]: { status: !edit[i].status },
                            }))
                          }
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {choosen === opt[1].title && (
          <table className="w-full">
            <thead>
              <tr className="bg-[#DCF4FF] h-[40px] text-left border-b border-[#AAE4FE] text-[#474747]">
                <th className="pl-[10px] border-r-2">No</th>
                <th className="pl-[15px]">Nama</th>
                <th>DevEui</th>
                <th>Penggunaan (m3)</th>
                <th>Tagihan (Rp)</th>
                <th>Status</th>
                <th>Bulan</th>
                <th>Tahun</th>
                <th>PDAM</th>
                <th>Dibayar Pada</th>
              </tr>
            </thead>
            <tbody>
              {data.map((x, i) => (
                <tr
                  key={i}
                  className="h-[40px] border-b border-[#AAE4FE] font-medium text-[#898989]"
                >
                  <td className="pl-[15px] border-r-2 font-bold">{i + 1}</td>
                  <td className="pl-[15px]">{x.nama}</td>
                  <td>{x.deveui}</td>
                  <td>{x.penggunaan / 1000}</td>
                  <td>{x.tagihan.toLocaleString("en")}</td>
                  <td>{x.status}</td>
                  <td>{x.bulan}</td>
                  <td>{x.tahun}</td>
                  <td>{x.pdam}</td>
                  <td>
                    {x.dibayar_pada === "0" ? "-" : humanTime(x.dibayar_pada)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {choosen === opt[2].title && (
          <table className="w-full">
            <thead>
              <tr className="bg-[#DCF4FF] h-[40px] text-left border-b border-[#AAE4FE] text-[#474747]">
                <th className="pl-[10px] border-r-2">No</th>
                <th className="pl-[15px]">DevEui</th>
                <th>Penggunaan (m3)</th>
                <th>Temperature (°C)</th>
                <th>Status</th>
                <th>Data Pada</th>
              </tr>
            </thead>
            <tbody>
              {data.map((x, i) => (
                <tr
                  key={i}
                  className="h-[40px] border-b border-[#AAE4FE] font-medium text-[#898989]"
                >
                  <td className="pl-[15px] border-r-2 font-bold">{i + 1}</td>
                  <td className="pl-[15px]">{x.deveui}</td>
                  <td>{x.volume / 1000}</td>
                  <td>{x.temperature}</td>
                  <td>{convertStatus(x.status)}</td>
                  <td>{humanTime(x.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {choosen === "cari" && (
          <table className="w-full">
            <thead>
              <tr className="bg-[#DCF4FF] h-[40px] text-left border-b border-[#AAE4FE] text-[#474747]">
                <th className="pl-[10px] border-r-2">No</th>
                <th className="pl-[15px]">Nama</th>
                <th>DevEui</th>
                <th>Penggunaan (m3)</th>
                <th>Tagihan</th>
                <th>Bulan</th>
                <th>Tahun</th>
                <th>Status</th>
                <th>Dibayar pada</th>
              </tr>
            </thead>
            <tbody>
              {data.map((x, i) => (
                <tr
                  key={i}
                  className="h-[40px] border-b border-[#AAE4FE] font-medium text-[#898989]"
                >
                  <td className="pl-[15px] border-r-2 font-bold">{i + 1}</td>
                  <td className="pl-[15px]">{x.nama}</td>
                  <td>{x.deveui}</td>
                  <td>{x.penggunaan / 1000}</td>
                  <td>{x.tagihan}</td>
                  <td>{x.bulan}</td>
                  <td>{x.tahun}</td>
                  <td>{x.status}</td>
                  <td>
                    {x.dibayar_pada === "0" ? "-" : humanTime(x.dibayar_pada)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Allusers;
